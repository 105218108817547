import "./project.scss"

import { Col, Row } from "react-simple-flex-grid"

import Footer from "./footer"
import Image from "./image"
import Layout from "./layout"
import Link from "./link"
import React from "react"
import SEO from "./seo"
import TopSection from "./top-section"
import { useIntl } from "gatsby-plugin-intl"

function Project(props) {
  const { pageContext } = props
  const { project, language } = pageContext
  const maxWidth = "1300px"
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={project.title} keywords={[project.id, ...project.techs]} />

      <div className="Project">
        <Row className="Project__Top TopSection__Separator">
          {/* TopSection */}
          <Col xs={12} className="Text__Light TopSection__Separator">
            <TopSection title={project.title} />
            <p className="Text__H3 Text__Lighten">{project.description[language]}</p>
          </Col>
        </Row>

        <div style={{ margin: "0 auto", textAlign: "center" }}>
          <Row className="Project__Description" style={{ maxWidth: "1500px", marginRight: "auto", marginLeft: "auto" }}>
            <Col className="Project__Description__Brief" xs={12} md={{ span: 6, offset: 1 }}>
              <p className="Text__Accent Text__H3">{intl.formatMessage({ id: "projects.brief" })}</p>
              {project.sections?.[language].map((x) => (
                <p key={x} dangerouslySetInnerHTML={{ __html: x }} className="Text__H4 Text__Regular Text__Black" />
              ))}
            </Col>

            <Col className="Project__Description__Overview" xs={12} md={{ span: 2, offset: 1 }}>
              <div className="Project__Date">
                <span className="Text__Black Text__H3">
                  {project.sponsor} | {project.wip ? `WIP - ${project.date}` : project.date}
                </span>
              </div>

              <div>
                <p className="Text__Accent Text__H3">{intl.formatMessage({ id: "projects.technologies" })}</p>
                {project.techs.map((x) => (
                  <span key={x} className="Project__Technology">
                    {x}
                  </span>
                ))}
              </div>

              {project.external && (
                <a
                  type="button"
                  href={project.external}
                  value="facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="Project__Button Text__H5"
                >
                  {intl.formatMessage({ id: "projects.viewOnline" })}
                </a>
              )}
            </Col>
          </Row>

          <Row className={`Image__${project.id.toUpperCase()} Project__Image`}>
            {project.images.map((x) => (
              <Col xs={12} key={x} style={{ maxWidth }} className="Project__Image__Col">
                <Image filename={project.id + "/" + x} alt={project.title + "-1"} />
              </Col>
            ))}
          </Row>
        </div>

        <Row>
          <Col xs={12} className="Project__Next">
            <Link to={"/project/" + project.next.id}>
              {intl.formatMessage({ id: "projects.next" })} - {project.next.title}
            </Link>
          </Col>
        </Row>
        <Footer />
      </div>
    </Layout>
  )
}

export default Project
